<template>
  <div>
    <!-- {{goodsInfo}} -->
    <OpenAppHead v-if="isShowOpenApp" :options="options" @getshowimg="getshowimg" />

    <div v-if="!this.$route.query.linktype" class="btm">
      <div
        v-if="goodsStatus == 2 && flashInfo && flashInfo.state !== 2"
        class="msTip"
      >
        <div class="msTipTitle">
          <img src="../../assets/images/naozhong.png"> 活动还剩{{
            countDownHour
          }}:{{ countDownMinute }}:{{ countDownSecond }}开始
        </div>
        <div v-if="isReserve === 1" class="msTipNo">已预约</div>
        <div v-else class="msTipSMS" @click="SMSChenge">短信预约</div>
      </div>
      <div
        v-if="
          goodsStatus != 7 &&
            goodsStatus != 8 &&
            goodsStatus != 9 &&
            goodsStatus != 10
        "
        class="le"
      >
        <div v-if="!isLife && goodsStatus != 7" class="icon-box" @click="scrollT">
          <img
            class="icon"
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-08-28/21/yuelvhuijGFmyj0rRn1598622592.png"
          >
          回到首页
        </div>
        <div
          v-if="!isLife && (goodsStatus == 1 || goodsStatus == 2) && goodsStatus != 7"
          class="icon-box"
          @click="routerLink('/shopCar')"
        >
          <span v-if="goodsInfo.cartTotal > 0" class="counts">{{
            goodsInfo.cartTotal
          }}</span>
          <img class="icon" src="../../assets/images/shop-car.png">购物车
        </div>
      </div>
      <!-- <div class="mi">分享海报</div> -->
      <!--老得-->
      <!--<div class="ri" @click="willBuy" v-if="isSale == 1">去购买</div>-->
      <!--新改的-->
      <!-- {{goodsInfo}} -->

      <!-- {{goodsStatus}} -->
      <!-- {{$store.state.skuInfo}} -->
      <div
        v-if="
          $store.state.skuInfo && $store.state.skuInfo.isSale.saleState === 0
        "
        class="ri2"
      >
        商品已售罄
      </div>
      <div
        v-if="
          (!$store.state.skuInfo && isSale == 1) ||
            ($store.state.skuInfo &&
              $store.state.skuInfo.isSale.saleState === 1 &&
              isSale == 1)
        "
        :style="{ width: goodsStatus == 9 ? '100%' : '' }"
      >
        <div v-if="goodsStatus == 2">
          <div
            v-if="flashInfo.state && flashInfo.state != 2"
            class="ri2"
            @click="countDown()"
          >
            <span>活动暂未开始</span>
          </div>

          <div v-else class="ri" style="margin: 0 auto" @click="willBuy">
            <span>购买送{{ goodsInfo.newShareScore.buy }}成长值</span>
          </div>
        </div>

        <div v-else-if="goodsStatus == 9" class="ri3" @click="willBuy">
          <span>购买送{{ goodsInfo.newShareScore.buy }}成长值</span>
        </div>
        <div
          v-else-if="goodsStatus == 10"
          class="footer_con_10"
        >
          <button open-type="share" class="footer_share">{{ goodsInfo.share_growth ? `分享赚${ goodsInfo.share_growth }成长值` : '分享商品' }}</button>
          <span class="footer_buy" @click="toOutside()">购买赚{{ goodsInfo.buy_growth }}成长值</span>
        </div>
        <div
          v-else
          class="ri"
          :style="{ background: goodsStatus == 6 ? '#000' : '#ff001b' }"
        >
          <div @click="willBuy">
            <div v-if="goodsInfo && goodsInfo.newShareScore">
              <p v-if="goodsInfo">购买预估送</p>
              <p v-if="goodsInfo">{{ goodsInfo.newShareScore.buy }}成长值</p>
            </div>

            <div v-else style="line-height: 30px">
              <div v-if="goodsStatus == 6" class="wphBtnBox">
                <p>购买送</p>
                <p>{{ goodsInfo }}成长值</p>
              </div>
              <div v-else>立即购买</div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isSale == 8" class="goods_good_btn">
        <div class="goods_good_bottom" @click="willBuy">我要开团</div>
      </div>

      <div v-if="isSale == 7" class="SpellGroupBtnWrap">
        <div class="SpellGroupBtnWrap1" @click="SpellGroupBuy(0)">
          <p>单独购买</p>
          <p>¥{{ goodsInfo.goodVipPrice }}</p>
        </div>
        <div class="SpellGroupBtnWrap2" @click="SpellGroupBuy(1)">
          <p>发起拼团</p>
          <p>¥{{ goodsInfo.assembleInfo.team_price }}</p>
        </div>
      </div>

      <div v-if="isSale == 0" class="ri2">已售罄</div>
      <div v-if="isSale == 10000" class="ri2">加载中</div>
    </div>

    <MiniCart
      v-if="cartData"
      :goods-status="goodsStatus"
      :datainfo="cartData"
      @success="addCartSuccess"
    />
    <!-- 暂时不需要 -->
    <div v-if="showimg" class="mask" @click="closeshow">
      <div class="picture-box">
        <img class="picture" src="../../assets/images/wxtwo.jpg">
        <div class="btn">长按图片保存到手机相册</div>
        <!-- <div class="btn" style="background: #ff7800">预览海报长按分享</div> -->
      </div>
    </div>

    <van-popup
      v-model="noshops"
      :close-on-click-overlay="false"
      :style="{
        width: '90%',
        height: '18%',
        'border-radius': '10px',
        padding: '10px',
      }"
    >
      <div class="noshopsBox">
        <div class="noshopsTitle">该商品暂无库存,请浏览其他相似商品</div>
        <div class="noshopsBtn">
          <span class="cancel" @click="cancelChange">取消</span>
          <span class="determine" @click="determineChange">确定</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
/* eslint-disable eqeqeq */
import Vue from 'vue'
import { Popup, Toast } from 'vant' // 提示框
Vue.use(Toast).use(Popup)
import MiniCart from '@/component/GoodsDetail/MiniCart'
import {
  skuChangeInfo,
  spellGroupOrderConfirm,
  flashGoodsReserve
} from '@/services/goods.js'
import OpenAppHead from '../../component/GoodsDetail/openApp'
import { getProductSkuDatail } from '@/services/api.js'
import { wxConfigInit } from '@/utils/wx-api'

var timer = null
export default {
  name: 'MallSkuInfo',
  components: {
    OpenAppHead,
    MiniCart
  },
  props: {
    isShowOpenApp: {
      type: Boolean,
      default: true
    },
    goodsInfo: {
      // 商品详情数据
      type: Object,
      default: null
    },
    goodsStatus: {
      // 1:自营详情；2:秒杀详情；3:京东详情；4:拼多多详情；5:淘宝详情；6:唯品会详情；7：拼团详情
      type: Number,
      default: 1
    },
    activityId: {
      type: String,
      default: ''
    },
    flashInfo: {
      // 秒杀
      type: Object,
      default: () => {
        return {}
      }
    },
    linkUrl: {
      type: String,
      default: ''
    },
    showtig: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isReserve: 1,
      noshops: false,
      cartData: null,
      labelText: '会员价',
      skuids: [], // 规格选中样式处理
      sign: [], // 选中索引标记
      txt: '', // 选中的文案
      goodsCount: 1, // 选择的数量
      goodsCode: '', // 商品编号

      isSale: 10000,
      status: 1,
      buyType: 0,
      saleList: [], // 规格
      showimg: false,
      dataTame: 1, // 秒杀
      // options:{
      //   showtig:true,
      //   info:{
      //     product_type:this.goodsStatus,
      //     id:this.goodsInfo.product_id || this.goodsInfo.goodId,
      //     skuId:this.goodsInfo.skuId || this.goodsInfo.productSkuId || (this.goodsInfo.saleList.length!==0&&this.goodsInfo.saleList[0].buttons.length!==0&&this.goodsInfo.saleList[0].buttons[0].skuList.length!==0)?this.goodsInfo.saleList[0].buttons[0].skuList[0]:''

      //   }
      // }
      countDownHour: '00',
      countDownMinute: '00',
      countDownSecond: '00',

      // 仰生活
      isLife: null
    }
  },
  computed: {
    options() {
      const obj = {
        showtig: true
      }

      const info = {
        product_type: this.goodsStatus,
        id:
          this.goodsInfo.product_id ||
          this.goodsInfo.goodId ||
          this.$route.query.goodsId ||
          this.$route.query.id ||
          '',
        skuId:
          this.goodsInfo.skuId ||
          this.goodsInfo.productSkuId ||
          (this.goodsInfo &&
            this.goodsInfo.saleList &&
            this.goodsInfo.saleList.length !== 0 &&
            this.goodsInfo.saleList[0].buttons.length !== 0 &&
            this.goodsInfo.saleList[0].buttons[0].skuList.length !== 0)
            ? this.goodsInfo.saleList[0].buttons[0].skuList[0]
            : '',
        activityId: this.$route.query.activityId,
        goodsId:
          this.goodsInfo.product_id ||
          this.goodsInfo.goodId ||
          this.$route.query.goodsId ||
          this.$route.query.id ||
          '',
        liveId: this.$route.query.live_id || this.$store.state.live_id || '',
        live_id:
          this.$route.query.live_people_id ||
          this.$route.query.live_mid ||
          this.$store.state.live_people_id ||
          '',
        codeNumber: this.$store.state.codeNumber || ''
      }
      obj.info = info
      return obj
    }
  },
  created() {
    const { isLife } = this.$route.query
    this.isLife = isLife
    // let vm = this;
    // window.onscroll = function() {
    //     if (document.documentElement.scrollTop > 60) {
    //         vm.isActive = true;
    //     } else {
    //         vm.isActive = false;
    //     }
    // };
  },
  mounted() {
    // if(this.$route.query.codeNumber){
    //     this.$store.commit('changeCodeNumber',this.$route.query.codeNumber)
    // }

    // this.$store.commit("changeShowSku", false);

    if (this.flashInfo) {
      this.isReserve = this.flashInfo.isReserve

      if (this.flashInfo.state == 1) {
        this.startTimer(this.flashInfo.startTime - this.flashInfo.nowTime)
      }
    }

    this.initData()

    this.configShare()
  },
  destroyed() {
    clearInterval(timer)
    // this.$store.commit("changeSkuIdx", 0); // 初始化规格索引
    this.$store.commit('changeSkuInfo', null)
    // this.$store.dispatch("wxConfigDefault", {});
  },

  methods: {
    toOutside() {
      // this.getLoginLinkFun()
      window.location.href = this.linkUrl
    },
    SMSChenge() {
      if (localStorage.getItem('uid')) {
        const body = {
          sku_id: this.flashInfo.productSkuId,
          mid: localStorage.getItem('uid'),
          sec_act_ext_id: this.flashInfo.activityId,
          goods_id: this.flashInfo.goodId
        }
        flashGoodsReserve(body).then((res) => {
          if (Number(res.code) === 200) {
            Toast(res.msg)
            this.isReserve = 1
          }
        })
      } else {
        this.$store.dispatch('loginIntercept', this.$route.query)
      }
    },
    startTimer(totalSecond) {
      // 倒计时
      // eslint-disable-next-line no-redeclare
      var totalSecond = totalSecond
      var interval1 = setInterval(
        function() {
          // 秒数

          var second = totalSecond
          // 小时位

          var hr = Math.floor(second / 3600)
          var hrStr = hr.toString()
          if (hrStr.length == 1) hrStr = '0' + hrStr
          // 分钟位
          var min = Math.floor((second - hr * 3600) / 60)
          var minStr = min.toString()
          if (minStr.length == 1) minStr = '0' + minStr
          // 秒位
          var sec = second - hr * 3600 - min * 60
          var secStr = sec.toString()

          if (secStr.length == 1) secStr = '0' + secStr
          this.countDownHour = hrStr
          this.countDownMinute = minStr
          this.countDownSecond = secStr
          totalSecond--
          if (totalSecond < 0) {
            clearInterval(interval1)
            this.countDownHour = '00'
            this.countDownMinute = '00'
            this.countDownSecond = '00'
          }
        }.bind(this),
        1000
      )
    },
    cancelChange() {
      const _that = this
      this.noshops = false
      setTimeout(() => {
        _that.$router.go(-1)
      }, 500)
    },
    determineChange() {
      const _that = this
      this.noshops = false
      setTimeout(() => {
        _that.$router.push('/goodsSearch')
      }, 500)
    },
    // 加入购物车成功的回调
    addCartSuccess(data) {
    },
    // 获取SKU详情
    countDown: function() {
      Toast('活动暂未开始')
    },
    closeshow() {
      this.showimg = false
    },
    getshowimg(item) {
      this.showimg = item
    },
    initData() {
      if (
        this.goodsStatus == 1 ||
        this.goodsStatus == 2 ||
        this.goodsStatus == 7 ||
        this.goodsStatus == 8
      ) {
        // 自营和秒杀才会初始化规格数据
        const mk = []
        if (this.goodsStatus == 7) {
          this.isSale = 7
          const { productInfo } = this.goodsInfo
          console.log('自营秒杀活动初始化数据 =>', productInfo)
          if (productInfo) {
            productInfo.saleList.forEach((item, index) => {
              mk[index] = []
              item.buttons.forEach((ele, idx) => {
                idx === 0 ? mk[index].push(true) : mk[index].push(false)
              })
            })
          }
        } else if (this.goodsStatus == 8) {
          this.isSale = 8
          return
        } else if (this.goodsStatus == 2) {
          this.isSale = 1
          this.goodsInfo.saleList.forEach((item, index) => {
            mk[index] = []
            item.buttons.forEach((ele, idx) => {
              idx === 0 ? mk[index].push(true) : mk[index].push(false)
            })
          })
        } else {
          this.goodsInfo.saleList.forEach((item, index) => {
            mk[index] = []
            item.buttons.forEach((ele, idx) => {
              idx === 0 ? mk[index].push(true) : mk[index].push(false)
            })
          })
          // this.isSale = 1
        }
        this.skuids = mk
        this.handleChose(0)
      } else {
        this.isSale = 1
      }
      //  this.isSale = 10000;
    },
    scrollT() {
      // // 滑动到顶部
      // timer = setInterval(function() {
      //     let osTop =
      //         document.documentElement.scrollTop ||
      //         document.body.scrollTop;
      //     let ispeed = Math.floor(-osTop / 5);
      //     document.documentElement.scrollTop = document.body.scrollTop =
      //         osTop + ispeed;
      //     // this.isTop = true;
      //     if (osTop === 0) {
      //         clearInterval(timer);
      //     }
      // }, 30);
      this.$router.replace({ path: '/' })
    },
    routerLink() {
      // 路由跳转
      // this.$router.push();
      this.$router.push({
        path: '/shopCar',
        query: { activityId: this.activityId }
      })
    },
    // 拼团购买
    SpellGroupBuy(type) {
      //  this.$store.commit("changeShowSku", true);
      if (type == 0) {
        // 单独购买
        this.buyType = 0
      } else if (type == 1) {
        // 拼团购买
        this.buyType = 1
      }
      this.willBuy()
    },
    // 发起拼团
    goSpellGroup() {
      const _this = this
      // 拼团订单确认
      const data = {
        team_id: _this.goodsInfo.assembleInfo.id, // id  '774',//
        goods_id: _this.goodsInfo.assembleInfo.goods_id, // 商品id '395608',
        sku_id: _this.goodsInfo.assembleInfo.sku_id, // '436936',
        uid: window.localStorage.getItem('uid'), // 用户id '1625104',
        goods_num: _this.goodsCount, // 商品数量 '1',
        area_id: _this.$store.state.addressInfo.addressId, // 地址ID '703576',
        type: '1', // 1开团2参团  '1',
        found_id: '0' // 团ID（开团没有，参团从分享地址中获取） '0',
      }
      // if(this.goodsStatus===8){
      // data.team_id = _this.goodsInfo
      // }
      //  return

      spellGroupOrderConfirm(data).then((res) => {
        if (Number(res.code) === 200) {
          this.$store.state.showSku = false
          // ?team_id=710&goodsId=1592557&product_sku_id=9669997&type=1&found_id=0&areaId=1_72_4211_0&productNum=1&addressIds=1067845
          this.$router.push({
            path: '/SpellGroupDetail',
            // path: "/placeOder",
            query: {
              team_id: _this.goodsInfo.assembleInfo.id,
              goods_id: _this.goodsInfo.assembleInfo.goods_id,
              sku_id: _this.goodsInfo.assembleInfo.sku_id,
              goods_num: _this.goodsCount,
              area_id: _this.$store.state.addressInfo.addressId,
              product_id: _this.goodsInfo.productInfo.product_id,
              goodsCode: this.goodsCode,
              areaIds: _this.$store.state.addressInfo.areaIds,
              chengzhangzhi:
              _this.goodsInfo.productInfo.newShareScore.buy,
              shareTitle: _this.goodsInfo.assembleInfo.share_title,
              shareDesc: _this.goodsInfo.assembleInfo.share_desc,
              shareImg: _this.goodsInfo.assembleInfo.share_img,
              type: 1
            }
          })
        }
      })
    },
    willBuy() {
      // 点击去购买
      if (window.localStorage.getItem('uid')) {
        if (
          this.goodsStatus == 1 ||
          this.goodsStatus == 2 ||
          this.goodsStatus == 7 ||
          this.goodsStatus == 8 ||
          this.goodsStatus == 9
        ) {
          // 只有自营详情,秒杀详情,拼团详情，才会显示规格弹窗
          const obj = {}

          if (this.goodsStatus == 7) {
            obj.buyType = this.buyType
            obj.id = this.goodsInfo.id
          } else if (this.goodsStatus == 8) {
            obj.id = this.$route.query.id
            obj.isGroup = 1
            //  obj.skuId =
          } else if (this.goodsStatus == 2 || this.goodsStatus == 9) {
            obj.goodId = this.goodsInfo.product_id
            obj.skuId =
              this.$route.query.skuid || this.$route.query.product_sku_id
            obj.activityId = this.$route.query.activityId
          } else {
            obj.goodId = this.goodsInfo.product_id
            obj.skuId =
              this.$route.query.skuid || this.$route.query.product_sku_id
          }
          this.cartData = obj
          if (this.goodsStatus == 7) {
            this.status = 7
          } else if (this.goodsStatus == 8) {
            this.status = 8
          } else {
            this.status = 1
          }

          if (this.goodsStatus == 8) {
            this.goSpellGroup()
            //  this.$router.push('/')
          } else {
            this.$store.commit('changeShowSku', true)
          }
          return
        }
        window.location.href = this._props.goodsInfo.buy_url
        //  window.location.href = 'https://cpsapi.xiaomiyoupin.com/cps/login?source=yuetao&feedback=145263/263/20201019213436&to=https%3A%2F%2Fm.xiaomiyoupin.com%2Fdetail%3Fgid%3D110068'
      } else {
        this.$store.dispatch('loginIntercept', this.$route.query)
      }
    },
    // 悦淘国际跳转认证
    Certification() {
      // this.goodsInfo.isIdentification===0
      this.$router.push('/Infoauthentication')
    },
    choseSku(index, idxs) {
      // 选择规格
      var mk = []
      this.goodsInfo.saleList[index].buttons.forEach((ele, idx) => {
        idx === idxs ? mk.push(true) : mk.push(false)
      })
      this.$set(this.skuids, index, mk)
      this.handleChose(idxs)
    },
    handleChose(idxs) {
      // 处理选中的规格，显示在‘已选’文案上
      const sign = []
      this.skuids.forEach((item, index) => {
        item.forEach((ele, idx) => {
          ele ? sign.push(idx) : null
        })
      })

      this.sign = sign
      let txt = ''
      if (this.goodsStatus != 7) {
        sign.forEach((item, index) => {
          txt =
            txt +
            ' ' +
            this.goodsInfo.saleList[index].buttons[item].text
        })
      } else {
        sign.forEach((item, index) => {
          txt =
            txt +
            ' ' +
            this.goodsInfo.productInfo.saleList[index].buttons[item]
              .text
        })
      }
      this.txt = txt
      // this.goodsCode = this.goodsInfo.saleList[0].buttons[idxs].skuList[this.sign[this.sign.length - 1]] || this.goodsInfo.saleList[0].buttons[idxs].skuList[0]
      this.handleSkuId() // 处理规格ID
      if (this.$store.state.addressReady) {
        this.skuChangeGoodsInfo(idxs)
      } else {
        setTimeout(() => {
          this.skuChangeGoodsInfo(idxs)
        }, 1000)
      }
    },
    handleSkuId() {
      // 处理规格ID
      const goodsInfo = this.goodsInfo
      const mk1 = []
      let mk2 = []
      let mkArr = []
      // 因为拼团商品详情所取字段不同，这里做了判断
      if (this.goodsStatus == 7) {
        const { productInfo } = goodsInfo
        if (productInfo) {
          for (var i = 0; i < goodsInfo.productInfo.saleList.length; i++) {
            mkArr = mkArr.concat(
              goodsInfo.productInfo.saleList[i].buttons[this.sign[i]].skuList
            )
          }
        }
      } else {
        for (let i = 0; i < goodsInfo.saleList.length; i++) {
          mkArr = mkArr.concat(
            goodsInfo.saleList[i].buttons[this.sign[i]].skuList
          )
        }
      }
      if (mkArr.length <= 1) {
        mk2 = mkArr
      } else {
        mkArr.forEach((item, index) => {
          if (mk1.indexOf(item) === -1) {
            mk1.push(item)
          } else {
            mk2.push(item)
          }
        })
      }

      this.goodsCode = mk2[0]
    },

    // 获取商品规格
    skuChangeGoodsInfo(idxs) {
      const live_id = this.$route.query.liveid || 0
      const shareId = localStorage.getItem('shareCodeNumber')
      sessionStorage.setItem('live_id', live_id)
      this.$store.commit('changeShowLoading', 1)
      if (this.goodsStatus == 2 || this.goodsStatus == 7) {
        const data = {

          shareId,
          addressCode: this.$store.state.addressInfo.areaIds || 0,
          uid: window.localStorage.getItem('uid') || 0,
          product_sku_id:
            this.$route.query.skuid || this.$route.query.product_sku_id
        }

        getProductSkuDatail(data).then((res) => {
          if (Number(res.code) === 200) {
            if (this.goodsStatus == 7) {
              const { productInfo } = this.goodsInfo
              if (productInfo) {
                this.goodsInfo.productInfo.banner[0] = res.data.skuImg
              }
            } else {
              this.goodsInfo.banner[0] = res.data.skuImg
            }

            //    if (this.goodsStatus == 7) {
            //   this.isSale = 7;
            // } else {
            //   this.isSale = res.data.isSale.saleState;
            // }
          } else if (Number(res.code) === 400) {
            this.noshops = true
          }
        })
      } else {
        const data = {

          shareId,
          liveId: this.$route.query.liveid || 0,
          addressCode: this.$store.state.addressInfo.areaIds || 0,
          uid: window.localStorage.getItem('uid') || 0,
          productSkuId:
            this.$route.query.skuid || this.$route.query.product_sku_id,
          productType: 0,
          lon: 0,
          lat: 0
        }

        skuChangeInfo(data).then((res) => {
          if (Number(res.code) === 200) {
            if (this.goodsStatus == 7) {
              this.goodsInfo.productInfo.banner[0] = res.data.skuImg
            } else {
              this.goodsInfo.banner[0] = res.data.skuImg
            }
            if (res.data.zhiboPrice > 0) {
              this.labelText = '直播价'
              this.goodsInfo.goodVipPrice = res.data.zhiboPrice
            } else {
              // 改变的 价格  先注销
              this.goodsInfo.goodVipPrice = res.data.vipPrice
            }
            // 改变的 价格  先注销
            this.goodsInfo.goodPrice = res.data.nowPrice
            if (this.goodsStatus == 7) {
              this.isSale = 7
            } else {
              this.isSale = res.data.isSale.saleState
            }
            this.$store.commit('changeSkuIdx', idxs)
          } else if (Number(res.code) === 400) {
            this.noshops = true
          }
        })
      }
    },
    // handleCount(bol) {
    //   // 减：false 加：true
    //   if (bol) {
    //     this.goodsCount++;
    //   } else {
    //     if (this.goodsCount == 1) {
    //       Toast("不能再少了呦");
    //       return;
    //     }
    //     this.goodsCount--;
    //   }
    // },
    // addShopCar() {
    //   if (this.goodsInfo.isIdentification === 0) {
    //     this.showPop = true;
    //   } else {
    //     if (
    //       this.goodsStatus == 1 &&
    //       this.goodsInfo.levelCantBuy > 0
    //     ) {
    //       Toast(this.goodsInfo.levelTips);
    //       return;
    //     }
    //     let data = {
    //       positionFrom: 0,
    //       productId: this.goodsInfo.product_id,
    //       productSkuId: this.goodsCode,
    //       uid: window.localStorage.getItem("uid") || "",
    //       productNum: this.goodsCount,
    //       joinLocation: "collage",
    //       activityId: this.$route.query.activityId,
    //     };
    //     addShopCar(data).then((res) => {
    //       if (Number(res.status) === 200) {
    //         Toast("加入购物车成功");
    //         if (this.goodsStatus == 1 || this.goodsStatus == 2) {
    //           // 刷新购物车数量
    //           this.$parent.getGoodsDetail();
    //         }
    //         this.$store.commit("changeShowSku", false);
    //         this.goodsCount = 1;
    //         this.initData();
    //       }
    //     });
    //   }
    // },
    // orderPay() {
    //   if (this.goodsInfo.isIdentification === 0) {
    //     this.showPop = true;
    //   } else {
    //     // 立即购买
    //     let data;
    //     if (this.goodsStatus == 7) {
    //       data = this.goodsInfo.productInfo;
    //     } else {
    //       data = this.goodsInfo;
    //     }
    //     let addressInfo = this.$store.state.addressInfo;
    //     if (!addressInfo.addressId) {
    //       Toast("请先选择收货地址");
    //       return;
    //     }
    //     if (this.goodsStatus == 1 && data.levelCantBuy > 0) {
    //       Toast(data.levelTips);
    //       return;
    //     }
    //     if (
    //       this.goodsStatus == 1 &&
    //       data.isFree == 1 &&
    //       data.canSend == 0 &&
    //       data.isNeedUpgrade == 1
    //     ) {
    //       // 白拿商品，等级不够时的提示
    //       Toast("会员等级不满足，请去升级会员");
    //       return;
    //     }
    //     let liveId = this.$store.state.live_id || this.$route.query.live_id;
    //     let live_people_id =
    //       this.$route.query.live_people_id ||
    //       this.$route.query.live_mid ||
    //       this.$store.state.live_people_id ||
    //       ""; // 主播ID
    //     if (this.goodsStatus == 1 || this.goodsStatus == 7) {

    //       // 自营订单|| 拼团单独购买订单
    //       this.$router.push(
    //         "/placeOder?productId=" +
    //           data.product_id +
    //           "&product_sku_id=" +
    //           this.goodsCode +
    //           "&addressIds=" +
    //           addressInfo.addressId +
    //           "&areaId=" +
    //           addressInfo.areaIds +
    //           "&addressType=0&productType=0&productNum=" +
    //           this.goodsCount +
    //           "&goodsId=" +
    //           data.product_id +
    //           "&codeNumber=" +
    //           this.$store.state.codeNumber +
    //           "&roomId=" +
    //           this.$store.state.room_id +
    //           "&robot_send=" +
    //           this.$store.state.robot_send +
    //           "&live_id=" +
    //           liveId +
    //           "&live_people_id=" +
    //           live_people_id +
    //           "&chengzhangzhi=" +
    //           data.newShareScore.buy
    //       );
    //     }
    //     if (this.goodsStatus == 2) {
    //       // 秒杀订单
    //       this.$router.push(
    //         "/placeModer?product_id=" +
    //           data.product_id +
    //           "&product_sku_id=" +
    //           this.goodsCode +
    //           "&activityId=" +
    //           this.$route.query.activityId +
    //           "&productType=0&addressIds=" +
    //           addressInfo.addressId +
    //           "&areaId=" +
    //           addressInfo.areaIds +
    //           "&productNum=" +
    //           this.goodsCount +
    //           "&codeNumber=" +
    //           this.$store.state.codeNumber +
    //           "&roomId=" +
    //           this.$store.state.room_id +
    //           "&robot_send=" +
    //           this.$store.state.robot_send +
    //           "&live_id=" +
    //           liveId +
    //           "&live_people_id=" +
    //           live_people_id +
    //           "&chengzhangzhi=" +
    //           data.newShareScore.buy
    //       );
    //     }
    //   }
    // },
    configShare() {
      const data = this.goodsInfo
      const shareInfo = {
        title: data.goodName, // 分享标题
        desc: data.goodsInfo, // 分享描述
        link:
          window.location.href +
          '&codeNumber=' +
          window.localStorage.getItem('recode'), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: data.banner[0] // 分享图标
      }
      wxConfigInit(shareInfo)
      // this.$store.dispatch('wxConfigInit', shareInfo)

      // 配置分享
      // var ua = navigator.userAgent.toLowerCase();
      // if (ua.match(/MicroMessenger/i) != "micromessenger") {
      //   return;
      // }
      // let data = this.goodsInfo;
      // wx.ready(function () {
      //   wx.onMenuShareTimeline({
      //     //分享到朋友圈
      //     title: data.goodName, // 分享标题
      //     desc: data.goodsInfo, // 分享描述
      //     link:
      //       window.location.href +
      //       "&codeNumber=" +
      //       window.localStorage.getItem("recode"), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      //     imgUrl: data.banner[0], // 分享图标
      //   });
      //   wx.onMenuShareAppMessage({
      //     //分享给朋友
      //     title: data.goodName, // 分享标题
      //     desc: data.goodsInfo, // 分享描述
      //     link:
      //       window.location.href +
      //       "&codeNumber=" +
      //       window.localStorage.getItem("recode"), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      //     imgUrl: data.banner[0], // 分享图标
      //   });
      // });
    }
  }
}
</script>

<style lang="less" scoped>
.btm {
  width: 100%;
  min-height: 50px;
  box-sizing: border-box;
  border-top: 1px solid #f8f8f8;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #fff;
  z-index: 1;
  padding-bottom: constant(safe-area-inset-bottom);///兼容 IOS<11.2/
  padding-bottom: env(safe-area-inset-bottom);///兼容 IOS<11.2/
  &.is-safe-area {
    height: 70px;
    padding-bottom: 20px;
  }
  // z-index:1000;
  .le {
    width: 163px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    color: #999999;
    background: #fff;

    .icon-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      .icon {
        display: block;
        width: 18px;
        height: 18px;
        margin-bottom: 3px;
      }

      .counts {
        min-width: 15px;
        height: 15px;
        padding: 2px 2px;
        border-radius: 50%;
        text-align: center;
        line-height: 15px;
        background: #ff001b;
        position: absolute;
        font-size: 12px;
        right: 0;
        top: -5px;
        color: #fff;
      }
    }
  }

  .mi {
    // width: 106px;
    // width: 50%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 15px;
    color: #fff;
    background: #201e1d;
    border-radius: 53px;
  }

  .ri {
    width: 212px;
    // width: 50%;
    height: 40px;
    text-align: center;
    // line-height: 40px;
    font-size: 14px;
    color: #fff;
    background: #ff001b;
    border-radius: 53px;
    padding: 4px 0;

    p {
      line-height: 16px;
    }

    span {
      line-height: 35px;
    }
  }

  .ri2 {
    width: 212px;
    // width: 50%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 15px;
    color: #fff;
    background: #444343;
    border-radius: 53px;
    margin: 0 auto;
  }

  .ri3 {
    width: 100%;
    height: 40px;
    background: #f85a03;
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-size: 15px;
  }

  .footer_con_10 {
    display: flex;

    .footer_share {
      background: #2d2d2d;
      color: #fff;
      padding: 0 20px;
      font-size: 13px;
      height: 40px;
      color: #fff;
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 50px 0px 0px 50px;
    }

    .footer_buy {
      padding: 0 20px;
      font-size: 13px;
      height: 40px;
      color: #fff;
      align-items: center;
      justify-content: center;
      display: flex;
      background: #e33f44;
      border-radius: 0px 50px 50px 0px;
    }
  }
}

.SpellGroupBtnWrap {
  width: 421px;
  height: 40px;
  display: flex;
  text-align: center;
  font-size: 12px;
  color: #fff;
  flex: 12;

  /*border-radius: 53px;*/
}

.SpellGroupBtnWrap1,
.SpellGroupBtnWrap2 {
  flex: 1;
  line-height: 20px;
}

.SpellGroupBtnWrap1 {
  border-radius: 20px 0 0 20px;
  background-color: #2d2d2d;
  line-height: 15px;
  padding-top: 7px;
}

.SpellGroupBtnWrap2 {
  border-radius: 0 20px 20px 0;
  background-color: #ca261a;
  line-height: 15px;
  padding-top: 7px;
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99;

  .picture-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .picture {
      display: block;
      width: 280px;
      // height: 360px;
      background: rebeccapurple;
      margin: 0 auto;
    }

    .btn {
      width: 290px;
      height: 44px;
      border-radius: 22px;
      background: #ed2e2e;
      text-align: center;
      line-height: 44px;
      font-size: 16px;
      color: #fff;
      margin-top: 15px;
    }
  }

  .sku {
    width: 100%;
    min-height: 500px;
    border-radius: 8px 8px 0 0;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    padding: 14px;

    .tp {
      display: flex;
      width: 100%;
      position: relative;
      margin-bottom: 20px;

      .goods-cover {
        width: 78px;
        height: 78px;
        border-radius: 4px;
        background: #e0e0e0;
      }

      .goods-price {
        width: 250px;
        height: 78px;
        display: flex;
        // align-items: center;
        flex-direction: column;
        justify-content: space-around;
        box-sizing: border-box;
        padding-left: 12px;

        .pris {
          text-align: left;

          .hjy {
            color: #ed2e2e;
            font-size: 13px;
          }

          .hjy-count {
            color: #ed2e2e;
            font-size: 18px;
            font-weight: bold;
            margin-right: 12px;
          }

          .spe-icon {
            font-size: 14px;
            color: #ed2e2e;
            font-weight: bold;
            margin-left: 4px;
          }

          .gwj {
            color: #141f33;
            font-size: 13px;
          }
        }

        .guige {
          text-align: left;
          font-size: 13px;
          color: #99a0ad;
        }

        .close {
          width: 16px;
          height: 16px;
          background: url("../../assets/images/closesku.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: 4px;
          top: 4px;
        }
      }
    }

    .out {
      height: 200px;
      overflow: hidden;
      margin-bottom: 50px;

      .inner {
        height: 200px;
        overflow: auto;
      }
    }

    .status-box {
      width: 100%;
      margin-bottom: 8px;

      .tits {
        font-size: 16px;
        color: #141f33;
        margin-bottom: 12px;
        text-align: left;
      }

      .status {
        width: 100%;
        overflow: hidden;

        .box {
          width: auto;
          padding: 6px 20px;
          background: #f0f2f5;
          border-radius: 4px;
          font-size: 14px;
          color: #141414;
          display: inline-block;
          margin-right: 12px;
          margin-bottom: 12px;
          float: left;
        }

        .act {
          background: #fae9e8;
          color: #ed2e2e;
        }
      }
    }
  }

  .num-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #141f33;
    margin-bottom: 23px;

    .handles {
      .edge,
      .add {
        display: inline-block;
        width: 28px;
        height: 28px;
        background: #f0f2f5;
        border-radius: 4px;
        color: #616b80;
        font-size: 12px;
        line-height: 28px;
      }

      .cou {
        display: inline-block;
        width: 45px;
        height: 28px;
        background: #f0f2f5;
        border-radius: 4px;
        color: #616b80;
        font-size: 12px;
        line-height: 28px;
        margin: 0 4px;
      }
    }
  }

  .code-txt {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #99a0ad;
    margin-bottom: 4px;
  }

  .btn-box {
    width: 100%;
    height: 44px;
    border-radius: 22px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .btn1 {
      width: 50%;
      height: 100%;
      line-height: 44px;
      background: #201e1d;
      color: #fff;
      font-size: 16px;
    }

    .btn2 {
      background: #ff001c;
    }

    .btn3 {
      width: 100%;
      height: 100%;
      line-height: 44px;
      color: #fff;
      font-size: 16px;
      background: #ff001c;
    }
  }
}

.goods_good_btn {
  width: 100%;

  .goods_good_bottom {
    width: 70%;
    height: 37px;
    margin: 2px auto;
    background: #e33f44;
    border-radius: 18px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #fff;
  }
}

.noshopsBox {
  .noshopsTitle {
    padding: 20px 0;
    font-size: 14px;
    color: #000;
  }

  .noshopsBtn {
    span {
      width: 80px;
      height: 25px;
      margin: 0 15px;
      border-radius: 15px;
      text-align: center;
      line-height: 25px;
      font-size: 12px;
      border: 1px solid red;
      display: inline-block;
    }

    .cancel {
      color: red;
    }

    .determine {
      background: red;
      color: #fff;
    }
  }
}

.msTip {
  position: absolute;
  width: 100%;
  height: 40px;
  background: #fffcd8;
  top: -40px;
  left: 0;
  right: 0;
  line-height: 40px;
  text-align: left;
  padding: 0 20px;

  .msTipTitle {
    color: #f43d00;

    img {
      width: 18px;
      height: 18px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .msTipSMS {
    position: absolute;
    // width: 40px;
    // height: 30px;
    right: 20px;
    top: 8px;
    font-size: 12px;
    padding: 2px 7px;
    border: 1px solid #f43d00;
    line-height: 20px;
    color: #f43d00;
    border-radius: 15px;
  }

  .msTipNo {
    position: absolute;
    background: #999;
    border: 1px solid #999;
    line-height: 20px;
    right: 20px;
    top: 8px;
    font-size: 12px;
    padding: 2px 7px;
    color: #fff;
    border-radius: 15px;
  }
}
</style>
